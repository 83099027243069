import { isDev } from '@tricks-gmbh/nuxt-tools/src/layers/base/helpers/gatherEnvVariables'
import de from './../../locales/de.json'
import en from './../../locales/en.json'
import type { I18nOptions } from 'vue-i18n'

export default {
  // https://vue-i18n.intlify.dev/api/general.html#legacy
  legacy: false,
  locale: 'de',
  fallbackLocale: 'en',
  messages: {
    de,
    en,
  },
  missing: (locale: string, key: string) => {
    if (!isDev) {
      return false
    }
    return `[${locale}] ${key} 🛑`
  },
  postTranslation: (str: string) => {
    if (!isDev) {
      return str
    }
    if ((str as string).includes('🛑')) {
      return str
    }
    return `${str} 🟢`
  },
} as I18nOptions
